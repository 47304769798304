<template>
  <div class="row">
<!--    <div class="col-lg-12">-->
<!--      <div class="row">-->
<!--        <div class="col-lg-12">-->
<!--          <div class="row">-->
<!--            <div class="col-lg-3 col-md-12">-->
<!--              <div class="input-group input-group-sm">-->
<!--                <span class="input-group-addon">-->
<!--                  <i class="fa fa-search"></i>-->
<!--                </span>-->
<!--                <input-->
<!--                  id="search"-->
<!--                  name="search"-->
<!--                  :placeholder="$t('aps.search')"-->
<!--                  class="form-control filters__input"-->
<!--                  v-model="filters.query"-->
<!--                />-->
<!--                <span class="clear-input text-muted">-->
<!--                  <i class="fa fa-remove" @click="searchClear"></i>-->
<!--                </span>-->
<!--              </div>-->
<!--            </div>-->
<!--            <div class="col-lg-3 col-md-12">-->
<!--              <div class="location-sort-in-snmp-statistic">-->
<!--                &lt;!&ndash;              <div>&ndash;&gt;-->
<!--                &lt;!&ndash;              <small>{{ $t('general.filtersSort') }}</small>&ndash;&gt;-->
<!--                &lt;!&ndash;              </div>&ndash;&gt;-->
<!--                <div class="input-group input-group-sm">-->
<!--                  <span class="input-group-addon filters__field-icon">-->
<!--                    <i-->
<!--                      class="fa fa-sort-amount-desc text-primary icon-sort"-->
<!--                      v-if="currSortMode === 'desc'"-->
<!--                      @click="toggleSortMode()"-->
<!--                    ></i>-->
<!--                    <i-->
<!--                      class="fa fa-sort-amount-asc text-primary icon-sort"-->
<!--                      v-if="currSortMode === 'asc'"-->
<!--                      @click="toggleSortMode()"-->
<!--                    ></i>-->
<!--                  </span>-->
<!--                  <select class="form-control form-control-sm filters__field" v-model="sortByFilter">-->
<!--                    <option :selected="true" value="Name">{{ $t('SNMPStat.sortName') }}</option>-->
<!--                    <option :selected="true" value="NASIP">{{ $t('SNMPStat.sortNASIP') }}</option>-->
<!--                    <option :selected="true" value="Client">{{ $t('SNMPStat.sortClient') }}</option>-->
<!--                    <option :selected="true" value="Location">{{ $t('SNMPStat.sortLocation') }}</option>-->
<!--                    <option :selected="true" value="Availability">{{ $t('SNMPStat.sortAvailability') }}</option>-->
<!--                    <option :selected="true" value="Uptime">{{ $t('SNMPStat.sortUptime') }}</option>-->
<!--                    <option :selected="true" value="AssociatedUsers">{{ $t('SNMPStat.sortAssociatedUsers') }}</option>-->
<!--                    <option :selected="true" value="NumberOfWLANs">{{ $t('SNMPStat.sortNumberOfWLANs') }}</option>-->
<!--                    <option :selected="true" value="TrafficTX">{{ $t('SNMPStat.sortTrafficTX') }}</option>-->
<!--                    <option :selected="true" value="TrafficRX">{{ $t('SNMPStat.sortTrafficRX') }}</option>-->
<!--                    &lt;!&ndash;                <option :selected="true" value="TrafficTXSpeed">{{ $t('SNMPStat.sortTrafficTXSpeed') }}</option>&ndash;&gt;-->
<!--                    &lt;!&ndash;                <option :selected="true" value="TrafficRXSpeed">{{ $t('SNMPStat.sortTrafficRXSpeed') }}</option>&ndash;&gt;-->
<!--                    <option :selected="true" value="Timestamp">{{ $t('SNMPStat.sortTimestamp') }}</option>-->
<!--                  </select>-->
<!--                </div>-->
<!--              </div>-->
<!--            </div>-->
<!--            <div class="col-lg-2 col-md-12">-->
<!--              <div class="input-group input-group-sm filters__location">-->
<!--                <span class="input-group-addon filters__field-icon">-->
<!--                  <i class="fa fa-building-o" :class="{ 'text-primary': filters.company !== 'All companys' }"></i>-->
<!--                </span>-->
<!--                <select-->
<!--                  @change="changeCompany"-->
<!--                  class="form-control form-control-sm filters__field"-->
<!--                  v-model="filters.company"-->
<!--                >-->
<!--                  <option :selected="true" :value="'All companys'">{{ $t('general.allCompanys') }}</option>-->
<!--                  <option v-for="company in companys" :value="company" :key="company.name">-->
<!--                    {{ company.name }}-->
<!--                  </option>-->
<!--                </select>-->
<!--              </div>-->
<!--            </div>-->
<!--            <div class="col-lg-2 col-md-12 input-group-for-location-select-in-statSNMP&#45;&#45;filters" v-if="filters.company === 'All companys'">-->
<!--&lt;!&ndash;              <div class="input-group input-group-sm filters__location">&ndash;&gt;-->
<!--&lt;!&ndash;                <span class="input-group-addon filters__field-icon">&ndash;&gt;-->
<!--&lt;!&ndash;                  <i class="fa fa-map-marker"></i>&ndash;&gt;-->
<!--&lt;!&ndash;                </span>&ndash;&gt;-->
<!--&lt;!&ndash;                <select @change="changeLocation" class="form-control form-control-sm filters__field" v-model="filters.location">&ndash;&gt;-->
<!--&lt;!&ndash;                  <option :selected="true" :value="'All locations'">{{ $t('general.allLocations') }}</option>&ndash;&gt;-->
<!--&lt;!&ndash;                  <option v-for="location in locationsList" :value="location.name" :key="location.id">&ndash;&gt;-->
<!--&lt;!&ndash;                    {{ location.name }}&ndash;&gt;-->
<!--&lt;!&ndash;                  </option>&ndash;&gt;-->
<!--&lt;!&ndash;                </select>&ndash;&gt;-->
<!--&lt;!&ndash;              </div>&ndash;&gt;-->
<!--                                                                     <span class="input-group-addon filters__field-icon">-->
<!--                  <i class="fa fa-map-marker"-->
<!--                     :class="{ 'text-primary': filters.location !== 'All locations' }"></i>-->
<!--                </span>-->
<!--                  <SelectComponentV2-->
<!--                    v-if="showLocationFilter"-->
<!--                    no-wrap-selected-option-->
<!--                    class="select-location-in-statSNMP&#45;&#45;filters"-->
<!--                    enable-max-option-width-->
<!--                    small-size-->
<!--                    show-delete-button-->
<!--                    v-model="locationInFiltersData"-->
<!--                    track-by="name"-->
<!--                    option-id-name="id"-->
<!--                    :no-options-found="$t('general.noResultForSearch')"-->
<!--                    :async-function="requestLocations.bind(this)"-->
<!--                    :placeholder="$t('portalStats.selectLocation')"-->
<!--                    @select="selectLocationInFilters"-->
<!--                  >-->
<!--                    <template #dropdown-start>-->
<!--                      <div class="ml-h mt-1">-->
<!--                        <Switch-component-->
<!--                          @input="refreshStatistic"-->
<!--                          :label="$t('general.withChild')"-->
<!--                          v-model="filters.with_childs"-->
<!--                          class=""-->
<!--                        />-->
<!--                      </div>-->
<!--                    </template>-->
<!--                  </SelectComponentV2>-->



<!--            </div>-->
<!--            <div class="col-lg-2 col-md-12" v-else>-->
<!--              <div class="input-group input-group-sm filters__location">-->
<!--                <span class="input-group-addon filters__field-icon">-->
<!--                  <i class="fa fa-map-marker"></i>-->
<!--                </span>-->
<!--                <select @change="changeLocation" class="form-control form-control-sm filters__field" v-model="filters.location">-->
<!--                  &lt;!&ndash;                <option :selected="true" :value="'All locations'">{{ $t('general.allLocations') }}&ndash;&gt;-->
<!--                  &lt;!&ndash;                </option>&ndash;&gt;-->
<!--                  <option-->
<!--                    v-for="location in locationsObjbyIds(this.filters.company.locations)"-->
<!--                    :value="location.name"-->
<!--                    :key="location.id"-->
<!--                  >-->
<!--                    {{ location.name }}-->
<!--                  </option>-->
<!--                </select>-->
<!--              </div>-->
<!--            </div>-->
<!--&lt;!&ndash;            <div class="d-flex align-items-center switcher-with-childs-for-use-in-filters-wrapper">&ndash;&gt;-->
<!--&lt;!&ndash;            <div&ndash;&gt;-->
<!--&lt;!&ndash;              :title="&ndash;&gt;-->
<!--&lt;!&ndash;                this.filters.hasOwnProperty('company') && this.filters.company !== 'All companys'&ndash;&gt;-->
<!--&lt;!&ndash;                  ? $t('aps.filterByCompanyDisableSortingByLocationMsg')&ndash;&gt;-->
<!--&lt;!&ndash;                  : ''&ndash;&gt;-->
<!--&lt;!&ndash;              "&ndash;&gt;-->
<!--&lt;!&ndash;              class="d-flex align-items-end justify-content-start"&ndash;&gt;-->
<!--&lt;!&ndash;            >&ndash;&gt;-->
<!--&lt;!&ndash;              <Switch-component&ndash;&gt;-->
<!--&lt;!&ndash;                :disabled="this.filters.location === 'All locations'"&ndash;&gt;-->
<!--&lt;!&ndash;                v-model="filters.with_childs"&ndash;&gt;-->
<!--&lt;!&ndash;                class="switcher-with-childs-for-use-in-filters"&ndash;&gt;-->
<!--&lt;!&ndash;                @change="refreshStatistic"&ndash;&gt;-->
<!--&lt;!&ndash;              />&ndash;&gt;-->
<!--&lt;!&ndash;            </div>&ndash;&gt;-->
<!--&lt;!&ndash;            <div class="input-group input-group-sm filters__location switcher-with-childs-for-use-in-filters-caption ">&ndash;&gt;-->
<!--&lt;!&ndash;              <small class="with-child">{{ $t('general.withChild') }}</small>&ndash;&gt;-->
<!--&lt;!&ndash;            </div>&ndash;&gt;-->
<!--&lt;!&ndash;          </div>&ndash;&gt;-->


<!--            &lt;!&ndash;            <div class="col-lg-3 col-md-12">&ndash;&gt;-->
<!--            &lt;!&ndash;            <div class="input-group input-group-sm filters__location ">&ndash;&gt;-->
<!--            &lt;!&ndash;                <span class="input-group-addon filters__field-icon">&ndash;&gt;-->
<!--            &lt;!&ndash;                  <i class="fa fa-building-o"&ndash;&gt;-->
<!--            &lt;!&ndash;                     :class="{ 'text-primary': filters.company !== 'All companys' }"></i>&ndash;&gt;-->
<!--            &lt;!&ndash;                </span>&ndash;&gt;-->
<!--            &lt;!&ndash;              <select&ndash;&gt;-->

<!--            &lt;!&ndash;                class="form-control form-control-sm filters__field"&ndash;&gt;-->
<!--            &lt;!&ndash;                v-model="filters.company"&ndash;&gt;-->
<!--            &lt;!&ndash;              >&ndash;&gt;-->
<!--            &lt;!&ndash;                <option :selected="true" :value="'All companys'">{{ $t('general.allCompanys') }}&ndash;&gt;-->
<!--            &lt;!&ndash;                </option>&ndash;&gt;-->
<!--            &lt;!&ndash;                <option v-for="company in companyList" :value="company" :key="company.name">&ndash;&gt;-->
<!--            &lt;!&ndash;                  {{ company.name}}&ndash;&gt;-->
<!--            &lt;!&ndash;                </option>&ndash;&gt;-->
<!--            &lt;!&ndash;              </select>&ndash;&gt;-->
<!--            &lt;!&ndash;            </div>&ndash;&gt;-->
<!--            &lt;!&ndash;          </div>&ndash;&gt;-->
<!--          </div>-->
<!--          <div class="row">-->
<!--            <div class="col-12">-->
<!--              <div class="mb-1">-->
<!--                <div>-->
<!--                  <small>{{ $t('SNMPStat.availability') }}</small>-->
<!--                </div>-->
<!--                <div class="btn-group btn-group-sm float-left mr-1" data-toggle="button">-->
<!--                  <label class="btn btn btn-outline-success" :class="{ active: filters.availability === 'All' }">-->
<!--                    <input type="radio" name="typeFilter" @click="setAvailabilityFilter('All')" />-->
<!--                    {{ $t('SNMPStat.filtersAvailablyAll') }}-->
<!--                  </label>-->
<!--                  <label class="btn btn btn-outline-success" :class="{ active: filters.availability === 'Availably' }">-->
<!--                    <input type="radio" name="typeFilter" @click="setAvailabilityFilter('Availably')" />-->
<!--                    {{ $t('SNMPStat.filtersAvailablyAvailably') }}-->
<!--                  </label>-->
<!--                  <label-->
<!--                    class="btn btn btn-outline-danger"-->
<!--                    :class="{ active: filters.availability === 'NotAvailably' }"-->
<!--                  >-->
<!--                    <input type="radio" name="typeFilter" @click="setAvailabilityFilter('NotAvailably')" />-->
<!--                    {{ $t('SNMPStat.filtersAvailablyNotAvailably') }}-->
<!--                  </label>-->
<!--                </div>-->
<!--              </div>-->
<!--            </div>-->
<!--          </div>-->
<!--          <div class="row mb-1">-->
<!--            <div class="col-12">-->
<!--              <button class="btn-outline-secondary btn btn-sm new-modern-style-btn-in-filters" @click="resetAllFilters">-->
<!--                <i class="fa fa-close"></i>-->
<!--                {{ $t('general.resetAllFilters') }}-->
<!--              </button>-->
<!--            </div>-->
<!--          </div>-->
<!--        </div>-->
<!--      </div>-->
<!--    </div>-->


    <div class="col-lg-12">
      <div class="row">
        <div class="col-lg-12">
          <div class="row">
            <div class="col-lg-3 col-md-12 mb-h">
              <div class="input-group input-group-sm">
                <span class="input-group-addon">
                  <i class="fa fa-search"></i>
                </span>
                <input
                  id="search"
                  name="search"
                  :placeholder="$t('aps.search')"
                  class="form-control filters__input"
                  v-model="filters.query"
                />
                <span class="clear-input text-muted">
                  <i class="fa fa-remove" @click="searchClear"></i>
                </span>
              </div>
            </div>

            <div  class="col-lg-3 col-md-12 mb-h" v-if="areLocationsVisible">
              <div class="input-group input-group-sm filters__location">
                <span class="input-group-addon filters__field-icon">
                  <i class="fa fa-building-o" :class="{ 'text-primary': filters.company !== 'All companys' }"></i>
                </span>
                <select
                    :disabled="isSelectedDefaultLocationForRequests"
                  @change="changeCompany"
                  class="form-control form-control-sm filters__field"
                  v-model="filters.company"
                >
                  <option :selected="true" :value="'All companys'">{{ $t('general.allCompanys') }}</option>
                  <option v-for="company in companys" :value="company" :key="company.name">
                    {{ company.name }}
                  </option>
                </select>
              </div>
            </div>
            <div class="col-lg-3 col-md-12 mb-h input-group-for-location-select-in-statSNMP--filters" v-if="filters.company === 'All companys'" v-show="areLocationsVisible">
<!--              <div class="input-group input-group-sm filters__location">-->
<!--                <span class="input-group-addon filters__field-icon">-->
<!--                  <i class="fa fa-map-marker"></i>-->
<!--                </span>-->
<!--                <select @change="changeLocation" class="form-control form-control-sm filters__field" v-model="filters.location">-->
<!--                  <option :selected="true" :value="'All locations'">{{ $t('general.allLocations') }}</option>-->
<!--                  <option v-for="location in locationsList" :value="location.name" :key="location.id">-->
<!--                    {{ location.name }}-->
<!--                  </option>-->
<!--                </select>-->
<!--              </div>-->
                                                                     <span class="input-group-addon filters__field-icon">
                  <i class="fa fa-map-marker"
                     :class="{ 'text-primary': filters.location !== 'All locations' }"></i>
                </span>
                  <SelectComponentV3ForUseInLocationSelectors

                    v-if="showLocationFilter"
                    no-wrap-selected-option
                    class="select-location-in-statSNMP--filters"
                    enable-max-option-width
                    small-size
                    :no-clear-selected-option-when-click-on-it="isSelectedDefaultLocationForRequests"
                   :show-delete-button="!isSelectedDefaultLocationForRequests"
                    v-model="locationInFiltersData"
                    track-by="name"
                    option-id-name="id"
                    :show-search-field-into-items-list="!isSelectedDefaultLocationForRequests || (isSelectedDefaultLocationForRequests && $store.getters.getDefaultWithChildsForRequests())"
                    :no-options-found="$t('general.noResultForSearch')"
                    :async-function="requestLocations.bind(this)"
                    :placeholder="$t('portalStats.selectLocation')"
                    @select="selectLocationInFilters"
                  >
                    <template #dropdown-start>
                      <div class="ml-h mt-1">
                        <Switch-component
                          v-if="!isSelectedDefaultLocationForRequests || (isSelectedDefaultLocationForRequests && $store.getters.getDefaultWithChildsForRequests())"
                          @input="refreshStatistic"
                          :label="$t('general.withChild')"
                          v-model="filters.with_childs"
                          class=""
                        />
                      </div>
                    </template>
                  </SelectComponentV3ForUseInLocationSelectors>



            </div>
            <div class="col-lg-3 col-md-12 mb-h" v-else v-show="areLocationsVisible">
              <div class="input-group input-group-sm filters__location">
                <span class="input-group-addon filters__field-icon">
                  <i class="fa fa-map-marker"></i>
                </span>
                <select @change="changeLocation" class="form-control form-control-sm filters__field" v-model="filters.location">
                  <!--                <option :selected="true" :value="'All locations'">{{ $t('general.allLocations') }}-->
                  <!--                </option>-->
                  <option
                    v-for="location in locationsObjbyIds(this.filters.company.locations)"
                    :value="location.name"
                    :key="location.id"
                  >
                    {{ location.name }}
                  </option>
                </select>
              </div>
            </div>

                        <div class="col-lg-3 col-md-12 mb-h">
              <div class="location-sort-in-snmp-statistic">
                <!--              <div>-->
                <!--              <small>{{ $t('general.filtersSort') }}</small>-->
                <!--              </div>-->
                <div class="input-group input-group-sm">
                  <span class="input-group-addon filters__field-icon">
                    <i
                      class="fa fa-sort-amount-desc text-primary icon-sort"
                      v-if="currSortMode === 'desc'"
                      @click="toggleSortMode()"
                    ></i>
                    <i
                      class="fa fa-sort-amount-asc text-primary icon-sort"
                      v-if="currSortMode === 'asc'"
                      @click="toggleSortMode()"
                    ></i>
                  </span>
                  <select class="form-control form-control-sm filters__field" v-model="sortByFilter">
                    <option :selected="true" value="Name">{{ $t('SNMPStat.sortName') }}</option>
                    <option :selected="true" value="NASIP">{{ $t('SNMPStat.sortNASIP') }}</option>
                    <option :selected="true" value="Client">{{ $t('SNMPStat.sortClient') }}</option>
                    <option :selected="true" value="Location">{{ $t('SNMPStat.sortLocation') }}</option>
                    <option :selected="true" value="Availability">{{ $t('SNMPStat.sortAvailability') }}</option>
                    <option :selected="true" value="Uptime">{{ $t('SNMPStat.sortUptime') }}</option>
                    <option :selected="true" value="AssociatedUsers">{{ $t('SNMPStat.sortAssociatedUsers') }}</option>
                    <option :selected="true" value="NumberOfWLANs">{{ $t('SNMPStat.sortNumberOfWLANs') }}</option>
                    <option :selected="true" value="TrafficTX">{{ $t('SNMPStat.sortTrafficTX') }}</option>
                    <option :selected="true" value="TrafficRX">{{ $t('SNMPStat.sortTrafficRX') }}</option>
                    <!--                <option :selected="true" value="TrafficTXSpeed">{{ $t('SNMPStat.sortTrafficTXSpeed') }}</option>-->
                    <!--                <option :selected="true" value="TrafficRXSpeed">{{ $t('SNMPStat.sortTrafficRXSpeed') }}</option>-->
                    <option :selected="true" value="Timestamp">{{ $t('SNMPStat.sortTimestamp') }}</option>
                  </select>
                </div>
              </div>
            </div>



<!--            <div class="d-flex align-items-center switcher-with-childs-for-use-in-filters-wrapper">-->
<!--            <div-->
<!--              :title="-->
<!--                this.filters.hasOwnProperty('company') && this.filters.company !== 'All companys'-->
<!--                  ? $t('aps.filterByCompanyDisableSortingByLocationMsg')-->
<!--                  : ''-->
<!--              "-->
<!--              class="d-flex align-items-end justify-content-start"-->
<!--            >-->
<!--              <Switch-component-->
<!--                :disabled="this.filters.location === 'All locations'"-->
<!--                v-model="filters.with_childs"-->
<!--                class="switcher-with-childs-for-use-in-filters"-->
<!--                @change="refreshStatistic"-->
<!--              />-->
<!--            </div>-->
<!--            <div class="input-group input-group-sm filters__location switcher-with-childs-for-use-in-filters-caption ">-->
<!--              <small class="with-child">{{ $t('general.withChild') }}</small>-->
<!--            </div>-->
<!--          </div>-->


            <!--            <div class="col-lg-3 col-md-12">-->
            <!--            <div class="input-group input-group-sm filters__location ">-->
            <!--                <span class="input-group-addon filters__field-icon">-->
            <!--                  <i class="fa fa-building-o"-->
            <!--                     :class="{ 'text-primary': filters.company !== 'All companys' }"></i>-->
            <!--                </span>-->
            <!--              <select-->

            <!--                class="form-control form-control-sm filters__field"-->
            <!--                v-model="filters.company"-->
            <!--              >-->
            <!--                <option :selected="true" :value="'All companys'">{{ $t('general.allCompanys') }}-->
            <!--                </option>-->
            <!--                <option v-for="company in companyList" :value="company" :key="company.name">-->
            <!--                  {{ company.name}}-->
            <!--                </option>-->
            <!--              </select>-->
            <!--            </div>-->
            <!--          </div>-->
          </div>
          <div class="row">
            <div class="col-12">
              <div class="mb-1">
                <div>
                  <small>{{ $t('SNMPStat.availability') }}</small>
                </div>
                <div class="btn-group btn-group-sm float-left mr-1" data-toggle="button">
                  <label class="btn btn btn-outline-info" :class="{ active: filters.availability === 'All' }">
                    <input type="radio" name="typeFilter" @click="setAvailabilityFilter('All')" />
                    {{ $t('SNMPStat.filtersAvailablyAll') }}
                  </label>
                  <label class="btn btn btn-outline-info" :class="{ active: filters.availability === 'Availably' }">
                    <input type="radio" name="typeFilter" @click="setAvailabilityFilter('Availably')" />
                    {{ $t('SNMPStat.filtersAvailablyAvailably') }}
                  </label>
                  <label
                    class="btn btn btn-outline-info"
                    :class="{ active: filters.availability === 'NotAvailably' }"
                  >
                    <input type="radio" name="typeFilter" @click="setAvailabilityFilter('NotAvailably')" />
                    {{ $t('SNMPStat.filtersAvailablyNotAvailably') }}
                  </label>
                </div>
              </div>
            </div>
          </div>
          <div class="row mb-1">
            <div class="col-12">
              <WButton secondary outline sm
                v-if="isFiltersActive"
                @click="resetAllFilters"
              >
                <i class="fa fa-close"></i>
                {{ $t('general.resetAllFilters') }}
              </WButton>
            </div>
          </div>
        </div>
      </div>
    </div>




    <div class="col-lg-12">
      <div class="cpes">
        <div class="cpes-rating-list animated fadeIn">
          <div class="animated fadeIn sticky-top">
            <div class="row">
              <div class="col-lg-12">
                <div class="card">
                  <div v-if="isSNMPStatisticLoading || isSNMPStatisticFiltering"><Loader-spinner></Loader-spinner></div>
                  <div class="card-header">
                    <i class="fa fa-bar-chart"></i>
                    {{ $t('SNMPStat.SNMPStatTitle') }}
                    <div class="float-right d-flex">
                      <date-picker
                        v-if="isDatePickerVisible"
                        v-model="drPickerDate"
                        :onChange="handleDateChange.bind(this)"
                      />

                      <TableDataDownloader
                        v-if="computedfilteredItems"
                        class="ml-h"
                        :data="formatToPDF(computedfilteredItems)"
                        :data-for-excel="formatToExcel(computedfilteredItems)"
                        :filename="getFileNameForDownloadFiles($t('downloadFilesNames.statSNMP'))"
                        :availableReports="{
                          csv: true,
                          xlsx: {
                            enabled: true,
                            autoColumnsWidth: true
                          },
                          pdf: {
                            enabled: true,
                            pageSize: 'A2'
                          }
                        }"
                      />
                    </div>
                  </div>
                  <div class="card-block table-block-snmp-statistic">
                    <table class="table">
                      <thead>
                        <tr>
                          <th>{{ $t('SNMPStat.id') }}</th>
                          <th>{{ $t('SNMPStat.name') }}</th>
                          <th>{{ $t('SNMPStat.nas_ip') }}</th>
                          <th>{{ $t('SNMPStat.client') }}</th>
                          <th>{{ $t('general.location') }}</th>
                          <th>{{ $t('SNMPStat.availability') }}</th>
                          <th>{{ $t('SNMPStat.uptime') }}</th>
                          <!--                          <th>{{ $t('SNMPStat.time_stamp') }}</th>-->
                          <th>{{ $t('SNMPStat.associated_users_small') }}</th>
                          <th>{{ $t('SNMPStat.wlans') }}</th>
                          <th>{{ $t('SNMPStat.traffic_tx_volume') }}</th>
                          <th>{{ $t('SNMPStat.traffic_rx_volume') }}</th>
                          <!--                          <th>{{ $t('SNMPStat.traffic_tx_speed') }}</th>-->
                          <!--                          <th>{{ $t('SNMPStat.traffic_rx_speed') }}</th>-->
                        </tr>
                      </thead>
                      <tbody>
                        <tr
                          v-for="(SNMPStatItem, index) in computedfilteredItems"
                          :data-index="index"
                          :data-id="SNMPStatItem.id"
                          :key="SNMPStatItem.id"
                          v-if="SNMPStatItem.id"
                          class="SNMPStatItem-row"
                        >
                          <td class="responsive-row">
                            <span v-if="SNMPStatItem.id">{{ SNMPStatItem.id }}</span>
                            <span v-else class="text-muted">{{ $t('misc.no_data') }}</span>
                          </td>
                          <td>
                            <span v-if="SNMPStatItem.name">{{ SNMPStatItem.name }}</span>
                            <span v-else class="text-muted">{{ $t('misc.no_data') }}</span>
                          </td>
                          <td>
                            <span v-if="SNMPStatItem.nas_ip">{{ SNMPStatItem.nas_ip }}</span>
                            <span v-else class="text-muted">{{ $t('misc.no_data') }}</span>
                          </td>
                          <td class="responsive-row">
                            <span v-if="SNMPStatItem.client">{{ SNMPStatItem.client }}</span>
                            <span v-else class="text-muted">{{ $t('misc.no_data') }}</span>
                          </td>
                          <td class="responsive-row">
                            <span v-if="SNMPStatItem.address">{{ SNMPStatItem.address }}</span>
                            <span v-else class="text-muted">{{ $t('misc.no_data') }}</span>
                          </td>
                          <td class="responsive-row">
                            <span v-if="SNMPStatItem.hasOwnProperty('availability')">
                              <span class="badge badge-success text-sm" v-if="SNMPStatItem.availability === true">
                                {{ $t('SNMPStat.availabilityAvailably') }}
                              </span>
                              <span class="badge badge-danger text-sm" v-if="SNMPStatItem.availability === false">
                                {{ $t('SNMPStat.availabilityNOTAvailably') }}
                              </span>
                            </span>
                            <span v-else class="text-muted">{{ $t('misc.no_data') }}</span>
                          </td>
                          <td class="responsive-row">
                            <span v-if="SNMPStatItem.uptime">{{ momentnow(SNMPStatItem.uptime) }}</span>
                            <span v-else class="text-muted">{{ $t('misc.no_data') }}</span>
                          </td>
                          <!--                          <td class="responsive-row">-->
                          <!--                            <span v-if="SNMPStatItem.time_stamp">{{ SNMPStatItem.time_stamp }}</span>-->
                          <!--                            <span v-else class="text-muted">{{ $t('misc.no_data') }}</span>-->
                          <!--                          </td>-->
                          <td class="responsive-row">
                            <span v-if="SNMPStatItem.hasOwnProperty('associated_users')">
                              {{ SNMPStatItem.associated_users }}
                            </span>
                            <span v-else class="text-muted">{{ $t('misc.no_data') }}</span>
                          </td>
                          <td class="responsive-row">
                            <span v-if="SNMPStatItem.wlans">
                              <span class="badge badge-success text-sm" v-for="wlanName in SNMPStatItem.wlans">
                                {{ wlanName }}
                              </span>
                            </span>
                            <span v-else class="text-muted">{{ $t('misc.no_data') }}</span>
                          </td>
                          <td class="responsive-row">
                            <span v-if="SNMPStatItem.traffic_tx_volume">
                              {{ formatKBytes(SNMPStatItem.traffic_tx_volume) }}
                            </span>
                            <span v-else class="text-muted">{{ $t('misc.no_data') }}</span>
                          </td>
                          <td class="responsive-row">
                            <span v-if="SNMPStatItem.traffic_rx_volume">
                              {{ formatKBytes(SNMPStatItem.traffic_rx_volume) }}
                            </span>
                            <span v-else class="text-muted">{{ $t('misc.no_data') }}</span>
                          </td>
                          <!--                          <td class="responsive-row">-->
                          <!--                            <span v-if="SNMPStatItem.traffic_tx_speed">{{ formatKBytes(SNMPStatItem.traffic_tx_speed) }}/s</span>-->
                          <!--                            <span v-else class="text-muted">{{ $t('misc.no_data') }}</span>-->
                          <!--                          </td>-->
                          <!--                          <td class="responsive-row">-->
                          <!--                            <span v-if="SNMPStatItem.traffic_rx_speed">{{ formatKBytes(SNMPStatItem.traffic_rx_speed) }}/s</span>-->
                          <!--                            <span v-else class="text-muted">{{ $t('misc.no_data') }}</span>-->
                          <!--                          </td>-->
                        </tr>

                        <tr v-if="!computedfilteredItems.length">
                          <td colspan="20" class="no-data-row">
                            <span class="text-muted pt-1">{{ $t('general.noDataToDisplay') }}</span>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from 'vue';
import VueNotifications from 'vue-notifications';
// import cpeService from '../../services/cpeService';
// import statCpeService from '../../services/statCpeService';
import commonService from '../../services/commonService';
// import Filters from '../../filters';
// import helpers from '../../helpers';
import DatePicker from '../../components/date-picker.vue';
import SNMPStatisticService from '../../services/SNMPStatisticService';
import Filters from '../../filters';
import helpers from '../../helpers';
import SwitchComponent from '../../components/Universal/Switch-component.vue';
import SelectComponentV2 from '../../components/Universal/select/select-componentV2.vue';
import locationService from '../../services/locationService';
import SelectComponentV3ForUseInLocationSelectors from '../../components/Universal/select/select-componentV3ForUseInLocationSelectors.vue';

export default {
  name: 'SNMPStat',
  components: { DatePicker, SwitchComponent, SelectComponentV2, SelectComponentV3ForUseInLocationSelectors },
  data() {
    return {
      showLocationFilter: true,
      locationInFiltersData: [],
      isDatePickerVisible: true,
      drPickerDate: {
        startDate: moment().startOf('day').valueOf(),
        endDate: moment().endOf('day').valueOf()
      },
      sortMode: 'asc',
      SNMPStatistic: [],
      searchQuery: '',
      filteredSNMPStatistic: [],
      drPickerDateForRating: {
        startDate: moment().startOf('day').valueOf(),
        endDate: moment().endOf('day').valueOf()
      },
      filters: {
        query: '',
        location: 'All locations',
        availability: 'All',
        company: 'All companys',
        locationId: '',
        with_childs: false
      },
      sortByFilter: 'Availability'
    };
  },
  computed: {
    isSelectedDefaultLocationForRequests() {
      if (this.$store.getters.getDefaultLocationForRequests(this)) {
        return true;
      } else {
        return false;
      }
    },
    userBaseLocation() {
      return this.$store.state.userData.base_location;
    },
    companys() {
      return this.$store.getters.getCompanyList;
    },
    companyList() {
      return this.$store.getters.companyListSorted;
    },
    isFiltersActive() {
      return (
        this.filters.query !== '' ||
          this.filters.location !== 'All locations' || this.filters.company !== 'All companys' ||
          this.filters.availability !== 'All' ||  this.filters.locationId !== ''
      );
    },
    computedfilteredItems() {
      return this.filterItems(this.SNMPStatisticList);
    },
    currSortMode() {
      return this.sortMode;
    },
    isSNMPStatisticLoading() {
      return this.$store.state.loadingSNMPStat;
    },
    isSNMPStatisticFiltering() {
      return this.$store.state.filteringSNMPStat;
    },
    SNMPStatisticList() {
      return this.SNMPStatistic;
    },
    locationsList() {
      // return this.$store.state.locationsList;
      // return helpers.sortLocationsList(this.$store.state.locationsList);
      return this.$store.getters.locationsListSorted;
    },
    isDisable() {
      return this.$store.state.userData.role === 'operator';
    }
  },
  methods: {
    changeLocation(event) {
      const locationName = event.target.value;
      let locationId = '';
      const locationObjectByName = this.locationsList.find((location) => {
        return location.name === locationName
      })
      if (!!locationObjectByName) {
        locationId = locationObjectByName.id;
      }
      console.log(locationName)
      if (locationName === 'All locations') {
        this.filters.locationId = '';
        this.filters.with_childs = true;
      } else {
        this.filters.locationId = locationId ?? '';
      }
      this.refreshStatistic()
    },
    changeLocationV2(location) {
      // console.log(location)
      const locationName = location.name ?? 'All locations';
      let locationId = '';
      const locationObjectByName = this.locationsList.find((location) => {
        return location.name === locationName
      })
      if (!!locationObjectByName) {
        locationId = locationObjectByName.id;
      }
      this.filters.location = locationName
      // console.log(locationName)
      if (locationName === 'All locations') {
        this.filters.locationId = '';
        this.filters.with_childs = true;
      } else {
        this.filters.locationId = locationId ?? '';
      }
      this.refreshStatistic()
    },
    refreshStatistic () {
      SNMPStatisticService.getSNMPStatistic(this);
    },
    getFileNameForDownloadFiles(pageName) {
      return helpers.getFileNameForDownloadFiles(pageName)
    },
    selectLocationInFilters(location) {
      // console.log(location)
      // if (!location) {
      //   this.filters.location = 'All locations'
      // } else {
      //   this.filters.location = location.id;
      //   // this.addLocationInPreviouslySelectedLocationsInFilter(location)
      // }
      // this.changeLocationV2(location)
      this.changeLocationV2(location)
      this.refreshStatistic()

    },
    async requestLocations(searchQuery) {
      // return locationService.requestLocations(searchQuery);
      // return locationService.requestLocationsWithSort(searchQuery);
      return locationService.requestLocationsWithSortAndFilteringByChildsForLocationSelectInPages(this, searchQuery)
    },
    toggleShowLocationFilter() {
      this.showLocationFilter = false;
      this.$nextTick(() => {
        this.showLocationFilter = true;
      })
    },
    changeCompany() {
      // console.log(val)
      let firstLocationNameForCompany = '';
      let firstLocationIdForCompany = '';
      if (this.filters.company.hasOwnProperty('locations') && this.filters.company.locations.length > 0) {
        firstLocationNameForCompany = commonService.locationObjbyId(this.filters.company.locations[0]).name;
        firstLocationIdForCompany = commonService.locationObjbyId(this.filters.company.locations[0]).id;
        // console.log(firstLocationIdForCompany);
      } else {
        // firstLocationNameForCompany = 'All locations';
        // firstLocationIdForCompany = '';
        firstLocationIdForCompany = this.$store.state.userData.base_location;
          if (firstLocationIdForCompany) {
            this.locationInFiltersData = commonService.locationObjbyId(firstLocationIdForCompany)
          }
      }
      // console.log('in change company')
      // console.log(firstLocationIdForCompany)
      // this.updatedPage.base_location = firstLocationIdForCompany;
      console.log('firstLocationNameForCompany', firstLocationNameForCompany)
      console.log('firstLocationIdForCompany', firstLocationIdForCompany);
      this.filters.location = firstLocationNameForCompany;
      this.filters.locationId = firstLocationIdForCompany;
      this.refreshStatistic()
    },
    locationsObjbyIds(ids) {
      return commonService.locationsObjbyIds(ids);
    },
    locationPartyGetField(name, field) {
      try {
        const filed = field;
        const showLocationPartyFromName = commonService.showLocationPartyFromLocationName(name);
        if (showLocationPartyFromName !== undefined && showLocationPartyFromName.hasOwnProperty(`${filed}`)) {
          return showLocationPartyFromName[field];
        }
        return '';
      } catch (e) {
        console.log(e);
        return '';
      }
    },
    locationName(id) {
      return commonService.showLocationFromId(id);
    },
    // resetAllFilters() {
    //   this.locationInFiltersData = [];
    //   this.toggleShowLocationFilter();
    //   this.filters.query = '';
    //   this.filters.location = 'All locations';
    //   this.filters.company = 'All companys';
    //   this.filters.availability = 'All';
    //   this.sortByFilter = 'Availability';
    //   this.filters.locationId = '';
    //   this.filters.with_childs = false;
    //   this.refreshStatistic();
    // },
    resetAllFilters() {
      // this.locationInFiltersData = [];
      // this.toggleShowLocationFilter();
      this.filters.query = '';
      // this.filters.location = 'All locations';
      this.filters.company = 'All companys';
      this.filters.availability = 'All';
      this.sortByFilter = 'Availability';
      // this.filters.locationId = '';
      // this.filters.with_childs = false;
      if (!this.isSelectedDefaultLocationForRequests) {
        this.filters.locationId = ''
        this.filters.location = 'All locations';
        this.filters.with_childs = false;
        this.locationInFiltersData = [];
      } else {
        this.filters.locationId = this.$store.getters.getDefaultLocationForRequests(this)
        this.filters.location = this.$store.getters.getInHeaderLocationSelectBlockData.selectedLocationObject.name
        this.filters.with_childs = this.$store.getters.getDefaultWithChildsForRequests(this)
        this.locationInFiltersData = this.$store.getters.getInHeaderLocationSelectBlockData.selectedLocationObject;
      }
      this.toggleShowLocationFilter();

      this.refreshStatistic();
    },
    SNMPStatisticAvailabilityFilter(SNMPStatisticArray, filterValue) {
      if (!SNMPStatisticArray) {
        return [];
      }
      const SNMPStatisticArrayFiltredByAvailability = JSON.parse(JSON.stringify(SNMPStatisticArray));
      if (filterValue !== 'Availably' && filterValue !== 'NotAvailably') {
        return SNMPStatisticArrayFiltredByAvailability;
      }
      if (filterValue === 'Availably') {
        return SNMPStatisticArrayFiltredByAvailability.filter((item) => {
          if (item.availability === true) {
            return true;
          }
          return false;
        });
      }
      if (filterValue === 'NotAvailably') {
        return SNMPStatisticArrayFiltredByAvailability.filter((item) => {
          if (item.availability === false) {
            return true;
          }
          return false;
        });
      }
    },
    setAvailabilityFilter(value) {
      this.filters.availability = value;
    },
    locationFilter(array, location) {
      if (location === 'All locations') {
        return array;
      }
      // console.log(location)
      // отрезаем от локации имя компании
      const locationLastIndexOfName = location.lastIndexOf('/');
      const locationCutCompanyName = location.slice(0, locationLastIndexOfName + 1);
      // console.log(locationCutCompanyName)
      return array.filter((item) => item.address === locationCutCompanyName);
    },
    SNMPStatisticTimestampFilter(SNMPStatisticArray, datePickerBounds) {
      if (!SNMPStatisticArray) {
        return [];
      }
      const startDateInUNIXFormat = datePickerBounds.startDate / 1000;
      const endDateInUNIXFormat = datePickerBounds.endDate / 1000;
      const SNMPStatisticArrayFiltredByDate = JSON.parse(JSON.stringify(SNMPStatisticArray));
      return SNMPStatisticArrayFiltredByDate.filter((item) => {
        if (startDateInUNIXFormat <= item.time_stamp && endDateInUNIXFormat >= item.time_stamp) {
          return true;
        }
        return false;
      });
    },
    redrawDatePicker() {
      this.isDatePickerVisible = false;
      this.$nextTick(() => {
        this.isDatePickerVisible = true;
      });
    },
    handleDateChange() {},
    searchClear() {
      this.filters.query = '';
    },
    formatToExcel(SNMPStatistic) {
      const result = {};
      if (SNMPStatistic.length === 0) {
        return result;
      }
      result[this.$t('SNMPStat.SNMPStatTitle')] = [];
      const SNMPStatisticToExcel = result[this.$t('SNMPStat.SNMPStatTitle')];
      SNMPStatisticToExcel.push([
        this.$t('SNMPStat.id'),
        this.$t('SNMPStat.name'),
        this.$t('SNMPStat.nas_ip'),
        this.$t('SNMPStat.client'),
        this.$t('general.location'),
        this.$t('general.company'),
        this.$t('SNMPStat.availability'),
        this.$t('SNMPStat.uptime'),
        // this.$t('SNMPStat.time_stamp'),
        this.$t('SNMPStat.associated_users_small'),
        this.$t('SNMPStat.wlans'),
        this.$t('SNMPStat.traffic_tx_volume'),
        this.$t('SNMPStat.traffic_rx_volume')
      ]);

      SNMPStatistic.forEach((cpe) => {
        let id = '';
        let name = '';
        let nas_ip = '';
        let client = '';
        let address = '';
        let availability = '';
        let uptime = '';
        let time_stamp = '';
        let associated_users = '';
        let wlans = '';
        let traffic_tx_volume = '';
        let traffic_rx_volume = '';
        let companyName = '';

        if (cpe.hasOwnProperty('id')) {
          id = cpe.id;
        }
        if (cpe.hasOwnProperty('name')) {
          name = cpe.name;
        }
        if (cpe.hasOwnProperty('nas_ip')) {
          nas_ip = cpe.nas_ip;
        }
        if (cpe.hasOwnProperty('client')) {
          client = cpe.client;
        }
        if (cpe.hasOwnProperty('address')) {
          address = cpe.address;
        }
        if (cpe.hasOwnProperty('availability')) {
          // cpe.availability = cpe.availability || '';
          if (cpe.availability === true) {
            availability = this.$t('SNMPStat.availabilityAvailably');
          } else {
            availability = this.$t('SNMPStat.availabilityNOTAvailably');
          }
        }
        if (cpe.hasOwnProperty('uptime')) {
          uptime = this.momentnow(cpe.uptime);
        }
        if (cpe.hasOwnProperty('time_stamp')) {
          time_stamp = cpe.time_stamp;
        }
        if (cpe.hasOwnProperty('associated_users')) {
          associated_users = cpe.associated_users;
        }
        if (cpe.hasOwnProperty('wlans') && Array.isArray(cpe.wlans) && cpe.wlans.length > 0) {
          // for (const wlanName of cpe.wlans) {
          //   wlans = wlans + wlanName + ' ';
          // }
          wlans = cpe.wlans.join(', ');
        }
        if (cpe.hasOwnProperty('traffic_tx_volume')) {
          traffic_tx_volume = this.formatKBytes(cpe.traffic_tx_volume) || '';
        }
        if (cpe.hasOwnProperty('traffic_rx_volume')) {
          traffic_rx_volume = this.formatKBytes(cpe.traffic_rx_volume) || '';
        }
        if (this.locationPartyGetField(address, 'name') !== '') {
          // console.log(this.locationPartyGetField(locationId, 'name'));
          companyName = this.locationPartyGetField(address, 'name');
        } else {
          companyName = this.$t('general.no').toLowerCase();
        }

        // console.log(cpe);
        SNMPStatisticToExcel.push([
          id,
          name,
          nas_ip,
          client,
          address,
          companyName,
          availability,
          uptime,
          associated_users,
          wlans,
          traffic_tx_volume,
          traffic_rx_volume
        ]);
      });

      return result;
    },
    formatToPDF(SNMPStatistic) {
      if (!SNMPStatistic) {
        return [];
      }

      let csvSNMPStatistic = JSON.parse(JSON.stringify(SNMPStatistic));

      for (const cpe of csvSNMPStatistic) {
        let companyName = '';
        if (this.locationPartyGetField(cpe.address, 'name') !== '') {
          // console.log(this.locationPartyGetField(locationId, 'name'));
          companyName = this.locationPartyGetField(cpe.address, 'name');
        } else {
          companyName = '';
        }
        cpe.companyName = companyName;
      }

      csvSNMPStatistic = csvSNMPStatistic.map((cpe) => {
        if (cpe.hasOwnProperty('id')) {
          cpe.id = cpe.id;
        } else {
          cpe.id = '';
        }
        if (cpe.hasOwnProperty('name')) {
          cpe.name = cpe.name;
        } else {
          cpe.name = '';
        }
        if (cpe.hasOwnProperty('nas_ip')) {
          cpe.nas_ip = cpe.nas_ip;
        } else {
          cpe.nas_ip = '';
        }
        if (cpe.hasOwnProperty('client')) {
          cpe.client = cpe.client || '';
        } else {
          cpe.client = '';
        }
        if (cpe.hasOwnProperty('address')) {
          cpe.address = cpe.address || '';
        } else {
          cpe.address = '';
        }
        if (cpe.hasOwnProperty('companyName')) {
          cpe.companyName = cpe.companyName || '';
        } else {
          cpe.companyName = this.$t('general.no').toLowerCase();
        }
        if (cpe.hasOwnProperty('availability')) {
          // cpe.availability = cpe.availability || '';
          if (cpe.availability === true) {
            cpe.availability = this.$t('SNMPStat.availabilityAvailably');
          } else {
            cpe.availability = this.$t('SNMPStat.availabilityNOTAvailably');
          }
        } else {
          cpe.availability = '';
        }
        if (cpe.hasOwnProperty('uptime')) {
          cpe.uptime = this.momentnow(cpe.uptime) || '';
        } else {
          cpe.uptime = '';
        }
        if (cpe.hasOwnProperty('time_stamp')) {
          cpe.time_stamp = cpe.time_stamp || '';
        } else {
          cpe.time_stamp = '';
        }
        if (cpe.hasOwnProperty('associated_users')) {
          cpe.associated_users = cpe.associated_users;
        } else {
          cpe.associated_users = '';
        }
        if (cpe.hasOwnProperty('wlans') && Array.isArray(cpe.wlans) && cpe.wlans.length > 0) {
          let wlansList = '';
          // for (const wlanName of cpe.wlans) {
          //   wlansList = wlansList + wlanName + ' ';
          // }
          wlansList = cpe.wlans.join(', ');
          cpe.wlans = wlansList;
        } else {
          cpe.wlans = '';
        }
        if (cpe.hasOwnProperty('traffic_tx_volume')) {
          cpe.traffic_tx_volume = this.formatKBytes(cpe.traffic_tx_volume) || '';
        } else {
          cpe.traffic_tx_volume = '';
        }
        if (cpe.hasOwnProperty('traffic_rx_volume')) {
          cpe.traffic_rx_volume = this.formatKBytes(cpe.traffic_rx_volume) || '';
        } else {
          cpe.traffic_rx_volume = '';
        }
        // дальше выстраиваем в определнном порядке, без этого в pdf данные перемешиваются между колонками
        // const SNMPStatisticKeys = ['id', 'sessions', 'offPeriod', 'name', 'model', 'base_location', 'connected'];
        const SNMPStatisticKeys = [
          'id',
          'name',
          'nas_ip',
          'client',
          'address',
          'companyName',
          'availability',
          'uptime',
          'associated_users',
          'wlans',
          'traffic_tx_volume',
          'traffic_rx_volume'
        ];
        const SNMPStatisticWithSortedKeys = {};
        const SNMPTranslateKeys = {
          id: this.$t('SNMPStat.id'),
          name: this.$t('SNMPStat.name'),
          nas_ip: this.$t('SNMPStat.nas_ip'),
          client: this.$t('SNMPStat.client'),
          address: this.$t('general.location'),
          companyName: this.$t('general.company'),
          availability: this.$t('SNMPStat.availability'),
          uptime: this.$t('SNMPStat.uptime'),
          // 'time_stamp': this.$t('SNMPStat.time_stamp'),
          associated_users: this.$t('SNMPStat.associated_users_small'),
          wlans: this.$t('SNMPStat.wlans'),
          traffic_tx_volume: this.$t('SNMPStat.traffic_tx_volume'),
          traffic_rx_volume: this.$t('SNMPStat.traffic_rx_volume')
        };
        for (const key of SNMPStatisticKeys) {
          SNMPStatisticWithSortedKeys[SNMPTranslateKeys[key]] = cpe[key];
        }
        delete cpe.time_stamp;
        return SNMPStatisticWithSortedKeys;
      });
      return csvSNMPStatistic;
    },
    toggleSortMode() {
      if (this.sortMode === 'desc') {
        this.sortMode = 'asc';
      } else {
        this.sortMode = 'desc';
      }
    },
    momentnow(value) {
      if (value) {
        const day = this.$t('general.dayShort');
        const min = this.$t('general.minShort');
        const h = this.$t('general.hourShort');
        const sec = this.$t('general.secShort');

        return moment.duration(value, 'seconds').format(`D [${day}], h [${h}], mm [${min}], ss [${sec}]`);
      }
    },
    formatKBytes(kbytes, decimal) {
      return this.formatBytes(kbytes * 1000, decimal);
    },
    formatBytes(bytes, decimals) {
      return commonService.formatBytes(bytes, decimals);
    },
    SNMPStatisticSearch(SNMPStatisticArray, searchQuery) {
      if (!SNMPStatisticArray) {
        return [];
      }
      const SNMPStatisticArrayFiltred = JSON.parse(JSON.stringify(SNMPStatisticArray));
      return SNMPStatisticArrayFiltred.filter((item) => {
        // if (item.id && item.name && item.nas_ip && item.client && item.address) {
        //   return (
        //     item.id.toLowerCase().indexOf(searchQuery.toLowerCase()) !== -1 ||
        //     item.name.toLowerCase().indexOf(searchQuery.toLowerCase()) !== -1 ||
        //     item.nas_ip.toLowerCase().indexOf(searchQuery.toLowerCase()) !== -1 ||
        //     item.client.toLowerCase().indexOf(searchQuery.toLowerCase()) !== -1 ||
        //     item.address.toLowerCase().indexOf(searchQuery.toLowerCase()) !== -1
        //
        //   );
        // }
        // if (item.hasOwnProperty('id') && item.id && item.id.toLowerCase().indexOf(searchQuery.toLowerCase()) !== -1) {
        //   return true;
        // }
        // if (item.hasOwnProperty('name') && item.name && item.name.toLowerCase().indexOf(searchQuery.toLowerCase()) !== -1) {
        //   return true;
        // }
        // if (item.hasOwnProperty('nas_ip') && item.nas_ip && item.nas_ip.toLowerCase().indexOf(searchQuery.toLowerCase()) !== -1) {
        //   return true;
        // }
        // if (item.hasOwnProperty('client') && item.client && item.client.toLowerCase().indexOf(searchQuery.toLowerCase()) !== -1) {
        //   return true;
        // }
        // if (item.hasOwnProperty('address') && item.address && item.address.toLowerCase().indexOf(searchQuery.toLowerCase()) !== -1) {
        //   return true;
        // }
        if (item.hasOwnProperty('id') && item.id && item.id.toLowerCase().includes(searchQuery.toLowerCase())) {
          return true;
        }
        if (item.hasOwnProperty('name') && item.name && item.name.toLowerCase().includes(searchQuery.toLowerCase())) {
          return true;
        }
        if (
          item.hasOwnProperty('nas_ip') &&
          item.nas_ip &&
          item.nas_ip.toLowerCase().includes(searchQuery.toLowerCase())
        ) {
          return true;
        }
        if (
          item.hasOwnProperty('client') &&
          item.client &&
          item.client.toLowerCase().includes(searchQuery.toLowerCase())
        ) {
          return true;
        }
        if (
          item.hasOwnProperty('address') &&
          item.address &&
          item.address.toLowerCase().includes(searchQuery.toLowerCase())
        ) {
          return true;
        }
        if (searchQuery) {
          return null;
        }
        return item;
      });
    },

    filterItems(items) {
      try {
        this.$store.commit('filteringSNMPStatBegin');
        // let filteredSNMPStatistic = items;
        let filteredSNMPStatistic = items;
        filteredSNMPStatistic = this.SNMPStatisticTimestampFilter(filteredSNMPStatistic, this.drPickerDate);
        // if (this.filters.hasOwnProperty('company') && this.filters.company !== 'All companys') {
        //   this.filters.location = this.filters.location = 'All locations';
        //   const locationName = this.locationName(this.filters.company.locationId) ? this.locationName(this.filters.company.locationId) : '';
        //   filteredSNMPStatistic = this.locationFilter(filteredSNMPStatistic, locationName);
        // }
        // console.log(this.filters.location)

        // filteredSNMPStatistic = this.locationFilter(filteredSNMPStatistic, this.filters.location);

        filteredSNMPStatistic = this.SNMPStatisticAvailabilityFilter(filteredSNMPStatistic, this.filters.availability);
        filteredSNMPStatistic = this.SNMPStatisticSearch(filteredSNMPStatistic, this.filters.query);
        // filteredSNMPStatistic = Filters.locationFilter(filteredSNMPStatistic, this.filters.location);
        // this.filteredSNMPStatistic = filteredSNMPStatistic;
        let sortedfilteredSNMPStatistic = [];
        let first;
        let second;
        if (this.sortMode === 'desc') {
          first = -1;
          second = 1;
        } else if (this.sortMode === 'asc') {
          first = 1;
          second = -1;
        }
        if (this.sortByFilter === 'Uptime') {
          sortedfilteredSNMPStatistic = filteredSNMPStatistic.sort((curr, prev) => {
            if (curr.uptime > prev.uptime) {
              return first;
            }
            if (curr.uptime < prev.uptime) {
              return second;
            }
            return 0;
          });
        } else if (this.sortByFilter === 'Name') {
          sortedfilteredSNMPStatistic = filteredSNMPStatistic.sort((curr, prev) => {
            const prevName = prev.name.toLowerCase();
            const currName = curr.name.toLowerCase();
            if (currName > prevName) {
              return first;
            }
            if (currName < prevName) {
              return second;
            }
            return 0;
          });
        } else if (this.sortByFilter === 'Availability') {
          sortedfilteredSNMPStatistic = filteredSNMPStatistic.sort((curr, prev) => {
            if (curr.availability > prev.availability) {
              return first * -1;
            }
            if (curr.availability < prev.availability) {
              return second * -1;
            }
            return 0;
          });
        } else if (this.sortByFilter === 'AssociatedUsers') {
          sortedfilteredSNMPStatistic = filteredSNMPStatistic.sort((curr, prev) => {
            if (curr.associated_users > prev.associated_users) {
              return first;
            }
            if (curr.associated_users < prev.associated_users) {
              return second;
            }
            return 0;
          });
        } else if (this.sortByFilter === 'TrafficTX') {
          sortedfilteredSNMPStatistic = filteredSNMPStatistic.sort((curr, prev) => {
            if (curr.traffic_tx_volume > prev.traffic_tx_volume) {
              return first;
            }
            if (curr.traffic_tx_volume < prev.traffic_tx_volume) {
              return second;
            }
            return 0;
          });
        } else if (this.sortByFilter === 'TrafficRX') {
          sortedfilteredSNMPStatistic = filteredSNMPStatistic.sort((curr, prev) => {
            if (curr.traffic_rx_volume > prev.traffic_rx_volume) {
              return first;
            }
            if (curr.traffic_rx_volume < prev.traffic_rx_volume) {
              return second;
            }
            return 0;
          });
        } else if (this.sortByFilter === 'TrafficTXSpeed') {
          sortedfilteredSNMPStatistic = filteredSNMPStatistic.sort((curr, prev) => {
            if (curr.traffic_tx_speed > prev.traffic_tx_speed) {
              return first;
            }
            if (curr.traffic_tx_speed < prev.traffic_tx_speed) {
              return second;
            }
            return 0;
          });
        } else if (this.sortByFilter === 'TrafficRXSpeed') {
          sortedfilteredSNMPStatistic = filteredSNMPStatistic.sort((curr, prev) => {
            if (curr.traffic_rx_speed > prev.traffic_rx_speed) {
              return first;
            }
            if (curr.traffic_rx_speed < prev.traffic_rx_speed) {
              return second;
            }
            return 0;
          });
        } else if (this.sortByFilter === 'Client') {
          sortedfilteredSNMPStatistic = filteredSNMPStatistic.sort((curr, prev) => {
            const prevClient = prev.client.toLowerCase();
            const currClient = curr.client.toLowerCase();
            if (currClient > prevClient) {
              return first;
            }
            if (currClient < prevClient) {
              return second;
            }
            return 0;
          });
        } else if (this.sortByFilter === 'NumberOfWLANs') {
          sortedfilteredSNMPStatistic = filteredSNMPStatistic.sort((curr, prev) => {
            let prevNumberOfWLANs;
            let currNumberOfWLAN;
            if (Array.isArray(prev.wlans)) {
              prevNumberOfWLANs = prev.wlans.length;
            } else {
              prevNumberOfWLANs = 0;
            }
            if (Array.isArray(curr.wlans)) {
              currNumberOfWLAN = curr.wlans.length;
            } else {
              currNumberOfWLAN = 0;
            }
            if (currNumberOfWLAN > prevNumberOfWLANs) {
              return first;
            }
            if (currNumberOfWLAN < prevNumberOfWLANs) {
              return second;
            }
            return 0;
          });
        } else if (this.sortByFilter === 'Location') {
          sortedfilteredSNMPStatistic = filteredSNMPStatistic.sort((curr, prev) => {
            const prevAddress = prev.address;
            const currAddress = curr.address;
            if (currAddress > prevAddress) {
              return first;
            }
            if (currAddress < prevAddress) {
              return second;
            }
            return 0;
          });
        } else if (this.sortByFilter === 'NASIP') {
          sortedfilteredSNMPStatistic = filteredSNMPStatistic.sort((curr, prev) => {
            let prevNASIP;
            let currNASIP;

            if (prev.nas_ip === '') {
              prevNASIP = '0.0.0.0'.split('.');
            } else {
              prevNASIP = prev.nas_ip.split('.');
            }

            if (curr.nas_ip === '') {
              currNASIP = '0.0.0.0'.split('.');
            } else {
              currNASIP = curr.nas_ip.split('.');
            }

            for (let i = 0; i < prevNASIP.length; i++) {
              if ((prevNASIP[i] = parseInt(prevNASIP[i])) < (currNASIP[i] = parseInt(currNASIP[i]))) return first;
              if (prevNASIP[i] > currNASIP[i]) return second;
            }
            return 0;
          });
        } else if (this.sortByFilter === 'Timestamp') {
          sortedfilteredSNMPStatistic = filteredSNMPStatistic.sort((curr, prev) => {
            const prevTimestamp = prev.time_stamp;
            const currTimestamp = curr.time_stamp;
            if (currTimestamp > prevTimestamp) {
              return first;
            }
            if (currTimestamp < prevTimestamp) {
              return second;
            }
            return 0;
          });
        }
        return sortedfilteredSNMPStatistic;
        // return items;
      } catch (e) {
        console.log(e);
        return JSON.parse(JSON.stringify(items));
      } finally {
        // console.log('finaly')
        this.$store.commit('filteringSNMPStatEnd');
      }
    },
    locationName(id) {
      return commonService.showLocationFromId(id);
    }
  },
  watch: {},
  created() {
    let inHeaderLocationSelectedApplying = true;
    if (this.$store.state.inHeaderLocationSelectModeEnabled &&
      inHeaderLocationSelectedApplying) {
      let locationNameForFiltering = '';
      let withChildsForFiltering = this.$store.getters.getInHeaderLocationSelectBlockData.withChilds;
      let locationIdForFiltering = this.$store.getters.getInHeaderLocationSelectBlockData.selectedLocationID;
      if (!locationIdForFiltering || locationIdForFiltering === '') {
        locationNameForFiltering = 'All locations'
        // console.log(this.$store.getters.getInHeaderLocationSelectBlockData.selectedLocationObject)
      }
      const selectedLocationObject = this.$store.getters.getInHeaderLocationSelectBlockData.selectedLocationObject
      if (!!selectedLocationObject){
        locationNameForFiltering = selectedLocationObject.name
      }

      if (locationNameForFiltering === 'All locations') {
        this.locationInFiltersData = [];
      } else {
        this.locationInFiltersData = this.$store.getters.getInHeaderLocationSelectBlockData.selectedLocationObject
      }

      this.filters.location = locationNameForFiltering;
      this.filters.locationId = locationIdForFiltering
      this.filters.with_childs = withChildsForFiltering;
    }
    SNMPStatisticService.getSNMPStatistic(this);
  },
  filters: {
    moment(value) {
      if (value) {
        return moment(value * 1000).format(' D MMM YYYY, H:mm ');
      }
    },

    toFixed(val, precission) {
      if (val && precission) {
        return val.toFixed(precission);
      }
    }
  },
};
</script>

<style lang="scss">
.cpes {
  display: flex;
}

.cpes-rating-list {
  width: 100%;
}

.cpes-stat {
  display: inline-block;
  width: 0;
  word-wrap: break-word;
}

.stat-visible .cpes-rating-list {
  transition: width, margin-right 0.2s;
  margin-right: 10px;
  width: 25%;
}

.stat-visible .cpes-stat {
  transition: width 0.2s;
  width: 75%;
}

.cpe-row {
  transition: background 0.15s;
  cursor: pointer;
}

.cpe-row.selected {
  background: #d9e3ec;
}

.cpe-row:hover {
  background: rgba(236, 236, 236, 0.59);
}

.cpe-row--sorted {
  background: rgba(236, 236, 236, 0.59);
}

.btn {
  cursor: pointer;
}

.stat-visible .progress {
  max-width: 130px;
}

.badge-status__breakdown {
  width: 5px !important;
  height: 5px !important;
  border-radius: 50% !important;
  margin-right: 0 !important;
  padding: 0;
}

.sessions {
  display: flex;
  justify-content: space-between;

  margin-bottom: 0.5rem;
}

div[data-popover='webaccess'] {
  background: #444;
  color: #f9f9f9;
  font-size: 12px;
  line-height: 1.5;
  margin: 3px;
  text-align: center;
  padding: 7px;
  height: 30px;
  width: 100%;
}

div[data-popover='analyzer'] {
  background: #444;
  color: #f9f9f9;
  font-size: 12px;
  line-height: 1.5;
  margin: 3px;
  text-align: center;
  padding: 7px;
  height: 30px;
  width: 100%;
}

.loader-backdrop-monitor-sessions:after {
  position: absolute;
  top: 30px;
}

.table-block-snmp-statistic {
  overflow-x: auto;
}

.filters-in-snmp-statistic {
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  gap: 1rem;

  & i.fa-circle-o-notch {
    color: #20a8d8;
  }

  .location-sort-in-snmp-statistic {
    width: 100%;
  }
}
</style>
<style>
  .input-group-for-location-select-in-statSNMP--filters {


    position: relative;
    display: flex;

    align-items: stretch;
    width: 100%;
    max-width: 100%;
    z-index: 9997 !important;

  }

  .select-location-in-statSNMP--filters .select__list-container {
    width: 400px;
  }
  .with-childs-switcher{
    margin-left: -1.5em !important;
  }
</style>
